import { AppShell } from '@mantine/core';
import { Outlet } from '@remix-run/react';
import Footer from './Footer';

const AuthEmptyLayout = () => {
  return (
    <AppShell footer={{ height: 50 }}>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
      <Footer />
    </AppShell>
  );
};

export default AuthEmptyLayout;
