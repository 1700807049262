import { AppShell, Center } from '@mantine/core';
import Subtitle1 from '~/components/Typograph/Subtitle1';

const Footer = () => {
  return (
    <AppShell.Footer withBorder={false} bg={'var(--secondary-light)'}>
      <Center h={'100%'}>
        <Subtitle1 c="var(--text-secondary)">
          Copyright © 2024 Nexus Healthcare Education Europe B.V. | All Rights
        </Subtitle1>
      </Center>
    </AppShell.Footer>
  );
};

export default Footer;
